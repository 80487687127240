import React from 'react';
import { Button } from './Controls';
import axios from 'axios';
import { BASE_URL } from '../api'

export default class ValidPayment extends React.Component {
  constructor(props) {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      error: false,
    };
  }

  openLightbox = (token) => {
    const paymentFields = {
      ssl_txn_auth_token: token
    };
    const callback = {
      onError: function (error) {
        axios.post(`${BASE_URL}/api/payments/error`, {
          error: error.toString(),
          message: 'There was an actual error',
          location: 'tools-app.src.components.ValidPayment.openLightbox'
        });
        this.props.history.push('/payments/error')
      },
      onCancelled: function () {
        axios.post(`${BASE_URL}/api/payments/error`, {
          error: 'None',
          message: 'Payment was cancelled',
          location: 'tools-app.src.components.ValidPayment.openLightbox'
        });
        console.log("cancelled", "");
      },
      onDeclined: (response) => {
        axios.post(`${BASE_URL}/api/payments/error`, {
          error: response,
          message: 'Not necessarily an error, just a declined card',
          location: 'tools-app.src.components.ValidPayment.openLightbox'
        });
        this.props.history.push('/payments/declined')
      },
      onApproval: (response) => {
        axios.post(`${BASE_URL}/api/payments/success`, {
          payment_id: this.props.id,
          details: {...response}
        });
        this.props.history.push('/payments/success', {
          ...response,
          document_id: this.props.data.document_id,
          jobName: this.props.data.jobName
        });
      }
    };
    // Following is manually imported
    // eslint-disable-next-line no-undef
    PayWithConverge.open(paymentFields, callback);
    return false;
  }

  initiateLightboxACH = () => {

    if (this.state.firstName === '' || this.state.lastName === '') {
      this.setState({error: true});
      return;
    }
    axios.post(`${BASE_URL}/api/payments/lightboxachtokeninit`, {
      payment_id: this.props.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName
    })
      .then((response) => {
        this.openLightbox(response.data);
      })
      .catch(() => {});
  };

  initiateLightboxCC = () => {
    
    if (this.state.firstName === '' || this.state.lastName === '') {
      this.setState({error: true});
      return;
    }
    axios.post(`${BASE_URL}/api/payments/lightboxcctokeninit`, {
      payment_id: this.props.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName
    })
      .then((response) => {
        this.openLightbox(response.data);
      })
      .catch(() => {});
  };

  render () {
    const data = this.props.data
    return (
      <>
        <div className="text-center mb-3 mt-3">
          <h2 className="text-black text-lg font-bold">
            Job Number: { data.document_id }
          </h2>
        </div>
        <div className="text-center mb-3">
          <h2 className="text-black text-lg font-bold">
            Job Name: { data.jobName }
          </h2>
        </div>
        <div className="text-center mb-3">
          <h2 className="text-black text-lg font-bold">
            Amount Due:
          </h2>
          <h2 className="text-bgreen text-3xl font-bold">
            ${ data.amount.toFixed(2) }
          </h2>
        </div>
        <div className="text-center mb-3">
          <input
            type="text"
            placeholder="First Name"
            className={`${this.state.error ? 'ring-bred ring' : 'outline-none'} px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border-0 shadow-md focus:outline-none focus:ring w-full`}
            onChange={e => this.setState({firstName: e.target.value})}
            onFocus={() => this.setState({error: false})}
          />
        </div>
        <div className="text-center mb-3">
          <input
            type="text"
            placeholder="Last Name"
            className={`${this.state.error ? 'ring-bred ring' : 'outline-none'} px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border-0 shadow-md focus:outline-none focus:ring w-full`}
            onChange={e => this.setState({lastName: e.target.value})}
            onFocus={() => this.setState({error: false})}
          />
        </div>
        <div className="text-center mb-4">
          <Button text="Pay with E-Check (Preferred)" onClick={this.initiateLightboxACH}/>
          <Button text="Pay with Credit Card" onClick={this.initiateLightboxCC}></Button>
        </div>
      </>
    );
  }
};
