import React from 'react';

export class Button extends React.Component {
  constructor(props) {
    super();
  }

  render () {
    const { text, onClick, className } = this.props
    return (
      <button
        className={"mb-2 py-2 px-4 uppercase bg-bblue text-white font-semibold rounded-sm shadow-md hover:bg-bblue-dark focus:outline-none focus:ring-2 focus:ring-bblue-light focus:ring-opacity-75" + className}
        onClick={onClick}
      >
        { text }
      </ button>
    )
  }

}

export class GradientButton extends React.Component {
  constructor (props) {
    super();
  }

  render () {
    const { text, onClick } = this.props
    return (
      <div className="flex justify-end pt-2">
        <button
          type="button"
          // className="transition-all duration-500 ease-in-out inline-block bg-gradient-to-r from-bgreen to-bblue hover:from-bred hover:to-byellow text-white font-semibold px-6 py-3 rounded-md"
          className="transition-all duration-250 ease-in-out inline-block bg-bgreen hover:bg-bgreen-dark text-white font-semibold px-6 py-3 rounded-md uppercase"
          onClick={onClick}
        >
          <span
            style={{
              textShadow: '0px 0px 12px #000000'
            }}
          >
            { text }
          </span>
        </button>
      </div>
    )
  }
}
export class Input extends React.Component {
  constructor(props) {
    super();
  }


  render() {
    const { style, placeholder, onChange, onKeyDown, onFocus, value } = this.props;
    const inputStyles = 'outline-none lg:w-4/12 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white text-sm border-1 shadow-md focus:outline-none focus:ring w-full '

    return (

        <input
          type="text"
          placeholder={placeholder}
          className={inputStyles + ' ' + style}
          onChange={e => { onChange(e.target.value) }}
          onKeyDown={e => { onKeyDown(e) }}
          onFocus={() => { return (onFocus) ? onFocus() : null }}
          value={value}
        />

    );

  }
}
