export const defaultColumns = {
  xs: true,
  sm: true,
  mdm: true,
  lg: true,
  xl: true,
}

export const DefaultOriginalColumns = {
  printoption: true,
  printing: true,
  quantity: true,
  stylenumber: true,
  stylecolor: true,
  other: true,
  otherdesc: true,
  description: true,
  itemprice: true,
  customprice: false,
  '3t': false,
  '6t': false,
  ysx: false,
  ys: false,
  ym: false,
  yl: false,
  xs: true,
  sm: true,
  mdm: true,
  lg: true,
  xl: true,
  xxl: false,
  xxxl: false,
  xxxxl: false,
  xxxxxl: false,
  xxxxxxl: false,
  misc: true
}

export const namesTemplate = [
  {
    label: "Design",
    id: "printoption",
    maxWidth: 110 
  },
  {
    label: "Qty",
    id: "quantity",
    maxWidth: 40,
    mask: "numbers",
    defaultValue: 0,
    hideable: true
  },
  {
    label: "Style",
    id: "stylenumber",
    hideable: true
  },
  {
    label: "Color",
    id: "stylecolor",
    hideable: true
  },
  {
    label: "Size",
    id: "size",
    hideable: true
  },
  {
    label: "Name",
    id: "name",
    hideable: true
  },
  {
    label: "Number",
    id: "number",
    mask: "numbers",
    hideable: true
  }
];

export const ColsTemplate = [
  {
    key: 'colorgroup',
    headerName: 'C',
    width: 28,
    minWidth: 28,
    editable: true,
    type: 'string',
    hideable: false
  },
  {
    key: 'quantity',
    headerName: 'Qty',
    width: 40,
    minWidth: 40,
    editable: false,
    hideable: false
  },
  {
    key: 'stylenumber',
    headerName: 'Style',
    editable: true,
    hideable: false,
    type: 'stylenumber'
  },
  {
    editable: true,
    key: 'color',
    headerName: 'Color',
    editable: true,
    hideable: false
  },
  {
    key: 'desc',
    headerName: 'Desc',
    editable: true,
    minWidth: 35,
    hideable: false,
    editable: false
  },
  {
    editable: true,
    key: 'nb',
    headerName: 'NB',
    editable: true,
    width: 40,
    minWidth: 40,
  },
  {
    key: 'm3',
    headerName: '3m',
    width: 40,
    minWidth: 40,
    editable: true,
  },
  {
    key: 'm6',
    headerName: '6m',
    width: 40,
    minWidth: 40,
    editable: true,
  },
  {
    key: 'm12',
    headerName: '12m',
    width: 42,
    minWidth: 42,
    editable: true,
  },
  {
    key: 'm18',
    headerName: '18m',
    width: 42,
    minWidth: 42,
    editable: true,
  },
  {
    key: 'm24',
    headerName: '24m',
    width: 45,
    minWidth: 45,
    editable: true,
  },
  {
    key: 't2',
    headerName: '2t',
    width: 35,
    minWidth: 35,
    editable: true,
  },
  {
    key: 't3',
    headerName: '3t',
    width: 35,
    minWidth: 35,
    editable: true,
  },
  {
    key: 't4',
    headerName: '4t',
    width: 35,
    minWidth: 35,
    editable: true,
  },
  {
    key: 't56',
    headerName: '5/6',
    width: 35,
    minWidth: 35,
    editable: true,
  },
  {
    key: 'xs',
    headerName: 'xs',
    width: 35,
    minWidth: 35,
    editable: true,
  },
  {
    key: 's',
    headerName: 's',
    width: 35,
    minWidth: 35,
    editable: true,
  },
  {
    key: 'm',
    headerName: 'm',
    width: 35,
    minWidth: 35,
    editable: true,
  },
  {
    key: 'l',
    headerName: 'l',
    width: 35,
    editable: true,
    minWidth: 35
  },
  {
    key: 'xl',
    headerName: 'xl',
    editable: true,
    width: 35,
    minWidth: 35
  },
  {
    key: 'xxl',
    headerName: 'xxl',
    editable: true,
    width: 35,
    minWidth: 35
  },
  {
    key: 'xl3',
    headerName: 'xl3',
    editable: true,
    width: 35,
    minWidth: 35
  },
  {
    key: 'xl4',
    headerName: 'xl4',
    editable: true,
    width: 35,
    minWidth: 35
  },
  {
    key: 'xl5',
    headerName: 'xl5',
    editable: true,
    width: 35,
    minWidth: 35
  },
  {
    key: 'xl6',
    headerName: 'xl6',
    editable: true,
    width: 35,
    minWidth: 35
  },
  {
    key: 'misc',
    headerName: 'Misc',
    editable: true,
    width: 45,
    minWidth: 35
  },
  {
    key: 'itemPrice',
    headerName: 'Item Price',
    minWidth: 35,
    hideable: false,
    editableFunction: (row) => {
      return row.customPriceOn ?? false;
    }
  },
  {
    key: 'lineTotal',
    headerName: 'Total',
    minWidth: 35,
    hideable: false,
    editable: false
  }
];

export const OldColsTemplate = [
  {
    label: 'Design',
    id: 'printoption',
    maxWidth: 60,
  },
  {
    label: 'Print',
    id: 'printing',
    maxWidth: 50,
  },
  {
    label: 'Qty',
    id: 'quantity',
    maxWidth: 40,
    mask: 'numbers',
    defaultValue: 0,
  },
  {
    label: 'Style',
    id: 'stylenumber',
    type: 'stylenumber',
  },
  {
    label: 'Item Description',
    id: 'description',
  },
  {
    label: 'Color',
    id: 'stylecolor',
  },
  {
    label: "NB",
    id: "NB",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "3m",
    id: "3m",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "6m",
    id: "6m",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "12m",
    id: "12m",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "18m",
    id: "18m",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "2t",
    id: "2t",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "3t",
    id: "3t",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "4t",
    id: "4t",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "5/6",
    id: "6t",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "xs",
    id: "xs",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "s",
    id: "sm",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "m",
    id: "mdm",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "l",
    id: "lg",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "xl",
    id: "xl",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "xxl",
    id: "xxl",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "xxxl",
    id: "xxxl",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "4xl",
    id: "xxxxl",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "5xl",
    id: "xxxxxl",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "6xl",
    id: "xxxxxxl",
    maxWidth: 35,
    mask: "numbers",
    type: "size",
  },
  {
    label: "Misc",
    id: "misc",
    maxWidth: 35,
    mask: "numbers",
    type: "size"
  },
  {
    label: "Other",
    id: "other",
    maxWidth: 35
  },
  {
    label: 'Desc',
    id: 'otherdesc',
  },
  {
    label: 'Price',
    id: 'itemprice',
    maxWidth: 70,
    format: 'currency',
    getIsDisabled: (row) => row.customprice !== true,
  },
  {
    label: 'Custom',
    id: 'customprice',
    maxWidth: 40,
    type: 'checkbox',
  },
]

export const document = {
  id: 'ZG9jdW1lbnQ6MzgwMzg=',
  jobname: 'Caffe Ibis Umbrellas',
  customerartobject: {
    type: 'mockups',
    idArray: [0, 1, 2, 3],
    cache_guid: 'EswNb5b9A',
    document_id: '35965',
    previewPath: '/previews/Erin_Anderson/35965/customerArtwork/',
  },
  customerartwork:
    '/docs/Deidra_Quire/38038/customerArtwork/Caffe_Ibis_Umbrellas.ai',
  duedate: null,
  document_id: '38038',
  total: 3827.09,
  totalPaid: null,
  lines: [
    {
      '3t': '',
      '6t': '',
      id: '3Wjha24V1',
      lg: '',
      sm: '6',
      xl: '',
      xs: '9',
      yl: '',
      ym: '',
      ys: '',
      mdm: '1',
      xxl: '',
      yxs: '',
      xxxl: '',
      label: 'Design',
      other: '',
      xxxxl: '',
      xxxxxl: '',
      xxxxxxl: '',
      maxWidth: 60,
      printing: '3x0',
      quantity: 16,
      itemprice: '11.20',
      linetotal: 179.2,
      otherdesc: '',
      pricingqty: 32,
      stylecolor: 'White',
      customprice: true,
      description: "District Women's Perfect Tri Racerback Tank",
      printoption: 'D1',
      stylenumber: 'DM138L|Colors ',
    },
    {
      '3t': '',
      '6t': '',
      id: 'VTvuhXt5Z',
      lg: '',
      sm: '',
      xl: '',
      xs: '',
      yl: '',
      ym: '',
      ys: '',
      mdm: '',
      xxl: '',
      yxs: '',
      xxxl: '',
      label: 'Design',
      other: '',
      xxxxl: '',
      xxxxxl: '',
      xxxxxxl: '',
      maxWidth: 60,
      printing: '',
      quantity: 16,
      itemprice: '4.00',
      linetotal: 64,
      otherdesc: '',
      pricingqty: 32,
      stylecolor: '',
      customprice: true,
      description: 'Cost To Print Custom Names',
      printoption: 'D1',
      stylenumber: 'Custom|Name',
    },
    {
      '3t': '',
      '6t': '',
      id: 'Evq9HWm_D',
      lg: '',
      sm: '6',
      xl: '',
      xs: '9',
      yl: '',
      ym: '',
      ys: '',
      mdm: '1',
      xxl: '',
      yxs: '',
      xxxl: '',
      label: 'Design',
      other: '',
      xxxxl: '',
      xxxxxl: '',
      xxxxxxl: '',
      maxWidth: 60,
      printing: '3x0',
      quantity: 16,
      itemprice: '17.99',
      linetotal: 287.84,
      otherdesc: '',
      pricingqty: 16,
      stylecolor: 'Blacktop',
      customprice: true,
      description: 'Ogio Endurance Ladies Racerback Pulse Tank',
      printoption: 'D2',
      stylenumber: 'LOE322|Colors',
    },
    {
      '3t': '',
      '6t': '',
      id: 'hUIbXJQrr',
      lg: '',
      sm: '8',
      xl: '',
      xs: '1',
      yl: '',
      ym: '',
      ys: '',
      mdm: '7',
      xxl: '',
      yxs: '',
      xxxl: '',
      label: 'Design',
      other: '',
      xxxxl: '',
      xxxxxl: '',
      xxxxxxl: '',
      maxWidth: 60,
      printing: 'No Print',
      quantity: 16,
      itemprice: '14.83',
      linetotal: 237.28,
      otherdesc: '',
      pricingqty: 16,
      stylecolor: 'Black/White/Black',
      customprice: true,
      description: 'Ladies Cadence Short',
      printoption: 'D3',
      stylenumber: 'LST304|Colors ',
    },
    {
      '3t': '',
      '6t': '',
      id: '_TU2TRNHp',
      lg: '',
      sm: '',
      xl: '',
      xs: '',
      yl: '',
      ym: '',
      ys: '',
      mdm: '',
      xxl: '',
      yxs: '',
      xxxl: '',
      label: 'Design',
      other: '2"',
      xxxxl: '',
      xxxxxl: '',
      xxxxxxl: '',
      maxWidth: 60,
      printing: '',
      quantity: 16,
      itemprice: '2.00',
      linetotal: 32,
      otherdesc: '',
      pricingqty: 16,
      stylecolor: 'White',
      customprice: true,
      description: 'Press On Number And Application',
      stylenumber: 'Press On|#',
    },
  ],
  linesConfig: {
    activeColumns: {
      lg: true,
      sm: true,
      xl: true,
      xs: true,
      mdm: true,
      xxl: true,
      xxxl: true,
    },
  },
  isreorder: 0,
  isweborder: 0,
  ismodro: null,
  lastorder: null,
  hasshipping: 0,
  priority: 'Standard',
  ordertype: null,
  tags: null,
  customer_id: '8333',
  shipNote: '',
  billto: '3371',
  specialinstructions: null,
  officenotes:
    'WFA Sent - 3/26\n3300IV - https://www.peerlessumbrella.com/product/style-3300iv/ \nCan you do the transfer on two sides??? :) +$6.90 for additional sides.',
  version: 2,
  workcenter: '2',
  art_object: null,
  isarchived: 0,
  customer: {
    id: 'Y3VzdG9tZXI6ODMzMw==',
    isorganization: false,
    organization: '',
    firstname: 'Deidra',
    lastname: 'Quire',
    address: '',
    city: '',
    state: 'UT',
    zipcode: '',
    primaryphone: '435-750-0193x5',
    email: 'deidra@caffeibis.com',
    __typename: 'customer',
  },
  customer_billto: {
    id: 'Y3VzdG9tZXI6MzM3MQ==',
    isorganization: false,
    organization: '',
    firstname: 'Business ',
    lastname: 'Miscellaneous',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    primaryphone: '000-000-0000 ',
    email: 'seecontactemail@0.com',
    __typename: 'customer',
  },
  __typename: 'document',
}
