import React from 'react';
import { Input, Button } from './Controls';
import axios from 'axios';
import { BASE_URL } from '../api';
import Footer from './Footer';
import '../App.css';
import Header from './Header';

const folders = [
    {
      name: 'Design',
      image: 'design_icon',
      color: '#ff7a45',
      code: '1',
      description: 'Order is for certain; artists create mockups based on items you pick and artwork you send in or create.',
    },
    {
      name: 'Approval',
      image: 'manager_check_icon',
      color: '#aaaaaa',
      code: '10',
      description: 'A survey we email that shows your items, sizes, quantities, mockups, and artwork colors/dimensions. This is your final chance to catch any changes you would like to fix before the job is sent to production.'
    },
    {
      name: 'Billing',
      image: 'billing_icon',
      color: '#9ccc64',
      code: '2',
      description: 'We send you an invoice. Once paid we will move it to the next process!'
    },
    {
      name: 'Order',
      image: 'order_icon-2',
      color: '#ed5851',
      code: '3',
      description: 'Your items have been ordered and are on their way to our shop to be printed.'
    },
    {
      name: 'Separations',
      image: 'separations_icon',
      color: '#ffeb3b',
      code: '4',
      description: 'Your artwork is prepared to be burned on a screen for printing.'
    },
    {
      name: 'Production',
      image: 'production_icon',
      color: '#7e58c2',
      code: '5',
      description: 'Your artwork is burned to a screen, items are counted and sorted, ink colors are mixed, and your job is printed.'
    },
    {
      name: 'Finished',
      image: 'finished_icon',
      color: '#78909c',
      code: '6',
      description: 'Your order is completed and ready for pick-up/shipping!'
    },
  ];

export default class TrackOrder extends React.Component {
  constructor(props) {
    super();
  }

  state = {
    id: '',
    workcenter: '6',
    selected: undefined,
    error: '',
    jobName: '',
    document_id: ''
  }

  changeId = (document_id) => {
    if(/^\d+$/.test(document_id) || document_id === '') {
      this.setState({document_id: document_id});
    } 
  }

  changeWorkcenter = (workcenter) => {
    this.setState({workcenter: workcenter});
  }

  checkEnter = (e) => {
    if(e.key === 'Enter') {
      this.trackOrder();
    }
  }

  trackOrder = () => {
    this.setState({ selected: undefined, jobName: undefined });
    if(Number(this.state.document_id) > Number.MAX_SAFE_INTEGER) {
      this.setState({error: 'It seems there was an error. Make sure you have the right workorder number'});
      return;
    }

    axios.post(`${BASE_URL}/api/document/track`, {
      document_id: this.state.document_id
    }).then(res => {
      this.setState({selected: res.data.workcenter, jobName: res.data.jobName });
      this.setState({error: ''});
    }).catch(err => {
      this.setState({selected: undefined});
    });
  }

  render () {
    return (
      <main 
        class="background-black w-full min-h-screen h-full flex justify-center"
      >
        <div class="w-full h-full lg:w-2/3 p-8 flex items-center flex-col">
          <div class="w-full blue-text neueblack uppercase text-3xl lg:text-5xl text-center">Track My Order</div>
          <div class="w-full neue text-center mt-2 text-xl lg:text-3xl text-white">Enter your Job ID to view the status of your order!</div>
          <div class="w-full flex justify-center items-center flex-row mt-2 mb-8 lg:my-8">
            <input 
              placeholder='Order Number'
              class="p-2 max-w-xs min-w-0 font-bold h-8"
              value={this.state.document_id}
              onChange={(e) => { this.setState({ document_id: e.target.value})}}
            />
            <button
              class="background-blue h-8 neueblack uppercase text-white flex-shrink-0 w-auto text-nowrap px-2"
              onClick={this.trackOrder}
            >Check Status</button>
          </div>
          { this.state.jobName && 
            <div
              class="text-4xl neueblack font-bold text-white mb-10"
            >
              Jobname: {this.state.jobName}
            </div>
          }
          <div
            class="w-full h-full flex justify-center flex-wrap"
          >
            {folders.map(folder => {
              return (
                <div
                  class="lg:w-1/2"
                >
                  <div
                    class="flex flex-row"
                    index="name"
                  >
                    <img
                      src={'https://cnd.badgerprints.com/web-assets/custom/icons/' + folder.image + '.svg'} 
                      alt="Design"
                      class="w-8 mr-2"
                      style={{ filter: folder.code === this.state.selected?.toString() ? '' : 'brightness(0) invert(1)' }}
                    ></img>
                    <div
                      class={"neueblack uppercase font-bold text-3xl text-white"}
                      style={{ color: folder.code === this.state.selected?.toString() ? folder.color : 'white'}}
                    >{folder.name}</div>
                  </div>
                  <div
                    class="flex flex-row pr-4 mb-2"
                  >
                    <div
                      class={'lg:opacity-0 mx-4 my-1 py-2 border-white border-l-2 w-0 h-14 ' + (folder.name === 'Finished' ? 'opacity-0' : '')}
                      style={{ borderColor: folder.code === this.state.selected?.toString() ? folder.color : 'white'}}
                    ></div>

                    <div
                      class="text-white text-xs lg:text-sm"
                      style={{ color: folder.code === this.state.selected?.toString() ? folder.color : 'white'}}
                    >{folder.description}</div>
                  </div>
                </div>
              )
            }) }
          </div>
          <div class="text-center text-white text-2xl w-2/3 font-bold hidden lg:block mb-10">
            Have questions regarding the status of your order? 
            Give us a call, we are more than happy to assist!
          </div>
          <div
            class="flex justify-center items-center w-full"
          >
            <a
              style={{ background: "url('https://cdn.badgerprints.com/OrderNowPage/ContactUsButton/Contact%20Us%20Hovered.svg')"}}
              class="w-2/3 lg:w-1/4"
              href="https://badgerprints.com/badgerprints/shop/page/contact"
            >
              <img
                src="https://cdn.badgerprints.com/OrderNowPage/ContactUsButton/Contact%20Us.svg"
                alt=""
                style={{imageRendering: 'crisp-edges'}}
                class="opacity-100 z-20 orderbutton hover:opacity-0"
              />
            </a>
          </div>
        </div>
      </main>
    )
  }

}




/*
.orderbutton:hover {
  opacity: 0;
}
.contact-us {
  background: url('https://cdn.badgerprints.com/OrderNowPage/ContactUsButton/Contact%20Us%20Hovered.svg')
}
import React from 'react';
import { Input, Button } from './Controls';
import axios from 'axios';
import { BASE_URL } from '../api';
import Footer from './Footer';

export default class TrackOrder extends React.Component {
  constructor(props) {
    super();
  }

  state = {
    id: '',
    workcenter: '6',
    selected: '',
    error: '',
    jobName: '',
    document_id: ''
  }

  changeId = (document_id) => {
    if(/^\d+$/.test(document_id) || document_id === '') {
      this.setState({document_id: document_id});
    } 
  }

  changeWorkcenter = (workcenter) => {
    this.setState({workcenter: workcenter});
  }

  componentDidMount() {
    if(this.state.selected === '') {
      this.rotateColor();
    }
  }

  checkEnter = (e) => {
    if(e.key === 'Enter') {
      this.trackOrder();
    }
  }

  trackOrder = () => {
    if(Number(this.state.document_id) > Number.MAX_SAFE_INTEGER) {
      this.setState({error: 'It seems there was an error. Make sure you have the right workorder number'});
      return;
    }

    axios.post(`${BASE_URL}/api/document/track`, {
      document_id: this.state.document_id
    }).then(res => {
      this.setState({selected: res.data.workcenter, jobName: res.data.jobName});
      this.setState({error: ''});
    }).catch(err => {
      this.setState({error: 'It seems there was an error. Make sure you have the right workorder number'});
      if(this.state.selected){
        this.setState({selected: '', jobName: ''});
        this.rotateColor();
      }
    });
  }

  rotateColor() {
    switch (this.state.workcenter) {
      case '-2':
      case '6':
        this.changeWorkcenter('7');
        break;
      case '7':
        this.changeWorkcenter('9');
        break;
      case '9':
        this.changeWorkcenter('1');
        break;
      case '1':
        this.changeWorkcenter('10');
        break;
      case '10':
        this.changeWorkcenter('2');
        break;
      case '2':
        this.changeWorkcenter('3');
        break;
      case '3':
        this.changeWorkcenter('4');
        break;
      case '4':
        this.changeWorkcenter('5');
        break;
      case '5':
        this.changeWorkcenter('6');
        break;
      default:
        this.changeWorkcenter('7');
        break;
    }
    if(this.state.selected === '') {
      setTimeout(() => { this.rotateColor() }, 2000)
    }
  }


  render () {
    return (
      <div className="container mx-auto px-4 h-full">
        <div className="flex flex-col content-center items-center justify-center min-h-full overflow-auto py-3" >
          <div className="w-full lg:w-11/12 md:w-8/12" >
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0" >
              <div className="rounded-t mb-0 px-6 py-6" >
                <div className="flex justify-center lg:justify-center flex-wrap mb-4">
                  <p className="text-2xl">
                    Track Your Order
                  </p>
                </div>
                <div className="flex justify-center mb-4">
                  <Input onChange={this.changeId} placeholder="Order Number" onKeyDown={this.checkEnter} value={this.state.document_id}></Input>
                  <Button text="Check Progress" onClick={this.trackOrder}  ></Button>
                  <br />
                </div>
                <div className="flex justify-center mb-4">
                  <p style={{
                    color: 'red',
                  }}>
                    {this.state.error}
                  </p>
                </div>
                <div className="flex justify-center flex-wrap" >
                  {this.folders.map((value, index) => {
                    return (
                      <img 
                        className="inline-flex mx-6 my-3"
                        src={'https://cnd.badgerprints.com/web-assets/custom/icons/' + value.image + '.svg'} 
                        alt=''
                        key={value.code}
                        style={{ 
                          filter: (value.code !== (this.state.selected || this.state.workcenter)) ? 'brightness(15%) saturate(70%)' : ''
                        }} 
                        width='70'
                      />
                    );
                  })}
                </div>
                <div className="flex justify-center flex-wrap" >
                  {this.state.selected && this.folders.map((value, index) => {
                    if(value.code === this.state.selected) {
                      return (
                        <div key={index}>
                          <hr />
                          <p className="text-center text-xl my-2">
                            Job: <strong>{this.state.jobName}</strong>
                          </p>
                          <p className="text-center text-l my-2" >
                            <strong>{value.name}</strong>
                          </p>
                          <p 
                            className="text-center"
                            dangerouslySetInnerHTML={{__html: value.description}}
                          >
                          </p>
                        </div>
                      );
                    } else {
                      return (
                        <>
                        </>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }

  folders = [
    {
      name: 'Quotes',
      image: 'quotes_icon',
      color: '#2ab6f6',
      code: '7',
      description: 'This is currently just a <strong>quote</strong> and will need <strong>more information</strong> before anything can be done.',
    },
    {
      name: 'Front Desk',
      image: 'front_desk_icon',
      color: '#ff3399',
      code: '9',
      description: `It looks like we are <strong>waiting</strong> for something on your end or this order has been <strong>pending approval</strong> for some time. If you would like to follow through with this order, please <strong>call</strong> or <strong>email</strong> to let us know.`,
    },
    {
      name: 'Design',
      image: 'design_icon',
      color: '#ff7a45',
      code: '1',
      description: 'Your job has entered the <strong>design</strong> process, this can take up to <strong>2 business days</strong> depending on <strong>work load</strong> of the designers and/or <strong>complexity</strong> of art. Once your <strong>art</strong> has been <strong>completed</strong>, it will be looked over by a <strong>supervisor</strong> to ensure everything is <strong>correct</strong>.',
    },
    {
      name: 'Manager Check',
      image: 'manager_check_icon',
      color: '#aaaaaa',
      code: '10',
      description: `A <strong>supervisor</strong> is looking over your order to make sure everything is <strong>correct</strong> for your proof to be sent to you. This will contain a <strong>visual representation</strong> of your product, <strong>sizes</strong>, and <strong>price</strong> for the project. Please note that this proof <strong>must</strong> be <strong>approved</strong> before anything can be started to <strong>order</strong> and <strong>produce</strong> your <strong>items</strong>.`,
    },
    {
      name: 'Billing',
      image: 'billing_icon',
      color: '#9ccc64',
      code: '2',
      description: `Thank you for filling out the approval form, your <strong>order</strong> is now pending to be <strong>paid</strong> for. If you have a <strong>purchase order number</strong> or are on terms then you don’t need to worry about this phase. If you <strong>don’t</strong> have either of those then your order will <strong>need</strong> to be <strong>paid</strong> for before we can <strong>order</strong> your <strong>items</strong>. Once <strong>paid</strong> for, we can start the <strong>ordering process</strong> and give you a <strong>scheduled</strong> print <strong>date</strong>.`,
    },
    {
      name: 'Order',
      image: 'order_icon-2',
      color: '#ed5851',
      code: '3',
      description: `Your <strong>items</strong> are being <strong>ordered</strong>, we will let you know if there are any <strong>issues</strong> with our <strong>vendors</strong>. No need to <strong>worry</strong> about your job any further until we call you for <strong>pickup</strong>!`,
    },
    {
      name: 'Separations',
      image: 'separations_icon',
      color: '#ffeb3b',
      code: '4',
      description: `While we are <strong>waiting</strong> for your items to <strong>arrive</strong>, our <strong>designers</strong> are making your art <strong>print ready</strong> to be sent to <strong>production</strong>.`,
    },
    {
      name: 'Production',
      image: 'production_icon',
      color: '#7e58c2',
      code: '5',
      description: `Your <strong>items</strong> have entered the <strong>production queue</strong> and we will <strong>call</strong> you once your job has been <strong>finished</strong>! This will be around the <strong>date</strong> that was <strong>given</strong> when the job was <strong>paid for</strong>.`,
    },
    {
      name: 'Finished',
      image: 'finished_icon',
      color: '#78909c',
      code: '6',
      description: `This <strong>project</strong> has been <strong>finished</strong>, <strong>thank you</strong> for your order! Please consider working with us again in the <strong>future</ strong>.`,
    },
  ]
}
*/
