import React from 'react';

export default class ValidPayment extends React.Component {
  constructor(props) {
    super()
    const details = props.history.location.state
    this.state = {
      first: details.ssl_first_name,
      last: details.ssl_last_name,
      card: details.ssl_card_number,
      amount: details.ssl_amount,
      short_card: details.ssl_card_short_description,
      time: details.ssl_txn_time,
      txn_id: details.ssl_txn_id,
      reference: details.ssl_transaction_reference_number,
      jobName: details.jobName,
      document_id: details.document_id
    };
  }

  render () {
    return (
    <>
      <div className="text-center mb-3">
        <h2 className="text-black text-lg font-bold">
          Your payment has been successfully processed.
        </h2>
      </div>
      <div className="mb-3">
        <p>Date: {this.state.time}</p>
        <p>Job Name: {this.state.jobName}</p>
        <p>Job ID: {this.state.document_id}</p>
        <p>Charged To: {`${this.state.first} ${this.state.last}`}</p>
        <p>Amount: ${this.state.amount}</p>
        <p>Card: {`${this.state.short_card} - ${this.state.card}`}</p>
        <p>Reference Number: {this.state.reference}</p>
      </div>
      <div className="text-center mb-3">
        <h2 className="text-black text-lg font-bold">
          If you have any questions please reach out to us!
        </h2>
      </div>
    </>
    )
  }
}