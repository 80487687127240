import React from 'react';
import axios from 'axios';
import { withRouter, useRouteMatch, Switch, Route } from 'react-router-dom';
import { ComponentTransition, AnimationTypes } from 'react-component-transition';
import { document, namesTemplate, ColsTemplate, OldColsTemplate, DefaultOriginalColumns } from '../helpers/templates';
import { BASE_URL } from '../api';
import Footer from './Footer';

export function ApprovalsRoute() {
  let match = useRouteMatch();

  return (
    <>
      <div className="container mx-auto px-4 sm:h-full min-h-full">
        <div className="flex flex-col content-center items-center justify-center min-h-full py-5">
          <div className="w-full min-h-full p-0.5 relative rounded-md sm:rounded-xl bg-gradient-to-br from-bblue via-bgreen to-bred">
            <div className="w-full min-h-full p-3 relative rounded-md sm:rounded-xl bg-white bg-opacity-95">

              <Switch>
                <Route path={`${match.path}/success`}>
                  <div className="md:max-w-screen-md m-auto">
                    <h3 className="text-center text-bgray text-lg font-bold mb-2">
                      Success!
                    </h3>
                    <p className="text-center text-bgray text-lg">
                      We have successfully received your response!
                    </p>
                    <p className="text-center text-bgray text-lg mt-3">
                      An email was sent with a copy of your workorder for your own records.
                    </p>
                    <p className="text-center text-bgray text-lg mt-3">
                      You'll hear from us soon to move your order on to the next step. If you have any other questions or concerns, please feel free to contact us at the info below!
                    </p>
                  </div>
                </Route>

                <Route path={`${match.path}/error`}>
                  <h3 className="text-center text-bgray text-lg font-bold mb-2">
                    Oh no...
                  </h3>
                  <p className="text-center text-bgray text-lg mb-2">
                    It seems that there was an error submitting your approval.
                    Please contact us using the info below to get your order moving along as soon as possible.
                  </p>
                  <p className="text-center text-bgray text-lg">
                    We appreciate your patience and support!
                  </p>
                </Route>

                <Route path={`${match.path}/bad-link`}>
                  <div className="lg:max-w-screen-md m-auto">
                    <h3 className="text-center text-bgray text-lg font-bold mb-2">
                      Looks like you clicked on a bad link.
                    </h3>
                    <p className="text-center text-bgray text-lg">
                      This means that either we deactivated this link, or you have already responded to the one that you tried to access.
                    </p>
                    <p className="text-center text-bgray text-lg pt-2">
                      Go ahead and try clicking on the link again. If that doesn't work please contact us at the information below and we can help you out.
                    </p>
                  </div>
                </Route>

                <Route path={`${match.path}/:approvalID`}>
                  <Approvals />
                </Route>

                <Route exact path={match.path}>
                  <h3 className="text-center text-bgray text-lg font-bold mb-2">
                    Hmm...{' '}
                  </h3>
                  <p className="text-center text-bgray text-lg">
                    Looks like you clicked on a bad link. Go ahead and try
                    again.
                  </p>
                  <p className="text-center text-bgray text-lg">
                    If you are still having trouble please reach out via the
                    contact info below. We'd love to hear from you.
                  </p>
                </Route>
              </Switch>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

class ApprovalsPage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      questions: {
      },
      uuid: props.match.params.approvalID,
      id: 0,
      rejectionRes: '',
      inputError: false,
      jobName: '',
      step: 'start',
      activeColumns: DefaultOriginalColumns,
      document,
      questionIndex: 0,
      approved: true,
      responseSent: false,
      to: '',
      alreadySurveyed: false,
      surveyQ1: false,
      surveyQ2: '',
      other: '',
      designs: [],
      version: 0
    };
  }

  componentDidMount = () => {
    axios.get(`${BASE_URL}/api/approvals/${this.props.match.params.approvalID}`)
    .then((res) => {
      console.log(res);
      this.setState({
        questions: res.data.setting,
        uuid: this.props.match.params.approvalID,
        id: res.data.document.document_id,
        inputError: false,
        jobName: res.data.document.jobname,
        rejectionRes: '',
        step: 'start',
        activeColumns: DefaultOriginalColumns,
        document: res.data.document,
        questionIndex: 0,
        approved: true,
        to: res.data.approval.to,
        version: res.data.document.version,
      }, () => {
        this.alreadySurveyed();
        if(res.data.document.version === 3) {
          this.getLines();
        }
      });
    }).catch(err => {
      this.props.history.push('/approvals/bad-link');
    });
  };

  alreadySurveyed = () => {
    axios.get(`${BASE_URL}/api/survey/answered`, {
      params: {
        email: this.state.to
      }
    })
      .then((res) => {
        this.setState({ alreadySurveyed: res.data.result });
      });
  };

  back = () => {
    const questions = this.state.questions;
    let questionIndex = this.state.questionIndex - 1;
    switch (this.state.step) {
      case 'start':
        break;
      case 'survey':
        this.setState({ step: 'start' });
        break;
      case 'meta':
        if (questionIndex < 0) {
          if(this.state.alreadySurveyed) {
            this.setState({ questionIndex: 0, step: 'start' });
          } else {
            this.setState({ step: 'survey' });
          }
        } else {
          this.setState({ questionIndex });
        }
        break;
      case 'lines':
        if (questionIndex < 0) {
          this.setState({ questionIndex: questions.meta.length - 1, step: 'meta' });
        } else {
          this.setState({ questionIndex });
        }
        break;
      case 'artwork':
        if (questionIndex < 0) {
          this.setState({ questionIndex: questions.lines.length - 1, step: 'lines' });
        } else {
          this.setState({ questionIndex });
        }
        break;
      case 'other':
        if (questionIndex < 0) {
          this.setState({ questionIndex: questions.artwork.length - 1, step: 'artwork' });
        } else {
          this.setState({ questionIndex });
        }
        break;
      case 'submit':
        this.setState({ questionIndex: questions.other.length - 1, step: 'other' });
        break;
      default:
        break;
    }
  };

  getLines = () => {
    axios.get(`${BASE_URL}/api/lines`, {
      params: {
        document_id: this.state.document.document_id
      }
    })
      .then((res) => {
        this.setState({ designs: res.data.results });
      });
  };

  neverMind = (step, questionIndex) => {
    let questions = this.state.questions;
    questions[step][questionIndex].response = undefined;
    this.setState({ questions });
  };

  next = () => {
    const questions = this.state.questions;
    let questionIndex = this.state.questionIndex + 1;
    this.isApproved();
    switch (this.state.step) {
      case 'start':
        if(this.state.alreadySurveyed) {
          this.setState({ step: 'meta' });
        } else {
          this.setState({ step: 'survey' });
        }
        break;
      case 'survey':
        if(!this.state.surveyQ2) {
          this.setState({ inputError: true });
          return;
        }
        this.setState({
          step: 'meta',
          inputError: false
        });
        break;
      case 'meta':
        if (questionIndex === questions.meta.length) {
          this.setState({ questionIndex: 0, step: 'lines' });
        } else {
          this.setState({ questionIndex });
        }
        break;
      case 'lines':
        if (questionIndex === questions.lines.length) {
          this.setState({ questionIndex: 0, step: 'artwork' });
        } else {
          this.setState({ questionIndex });
        }
        break;
      case 'artwork':
        if (questionIndex === questions.artwork.length) {
          this.setState({ questionIndex: 0, step: 'other' });
        } else {
          this.setState({ questionIndex });
        }
        break;
      case 'other':
        if (questionIndex === questions.other.length) {
          this.setState({ questionIndex: 0, step: 'submit' });
        } else {
          this.setState({ questionIndex });
        }
        break;
      default:
        break;
    }
  };

  questionNext = (step, questionIndex) => {
    if (this.state.questions[step][questionIndex].response !== '') {
      this.next();
    } else {
      this.setState({ inputError: true });
    }
  };

  setResponse = (step, questionIndex, value) => {
    let questions = this.state.questions;
    questions[step][questionIndex].response = value;
    this.setState({ questions, inputError: false });
  };

  showWhy = (step, questionIndex) => {
    let questions = this.state.questions;
    if (questions[step][questionIndex].response === undefined) {
      questions[step][questionIndex].response = '';
      this.setState({ questions });
    }
  };

  isApproved = () => {
    let approved = true;
    const questions = this.state.questions;
    questions.meta.forEach(e => {
      if (e.response) {
        approved = false;
      }
    });
    questions.lines.forEach(e => {
      if (e.response) {
        approved = false;
      }
    });
    questions.artwork.forEach(e => {
      if (e.response) {
        approved = false;
      }
    });
    questions.other.forEach(e => {
      if (e.response) {
        approved = false;
      }
    });
    this.setState({ approved });
  };

  getUserResponses = () => {
    let response = '';

    const questions = this.state.questions;
    questions.meta.forEach(e => {
      if (e.response) {
        response += e.question + ': ' + e.response + '\n';
      }
    });
    questions.lines.forEach(e => {
      if (e.response) {
        response += e.question + ': ' + e.response + '\n';
      }
    });
    questions.artwork.forEach(e => {
      if (e.response) {
        response += e.question + ': ' + e.response + '\n';
      }
    });
    questions.other.forEach(e => {
      if (e.response) {
        response += e.question + ': ' + e.response + '\n';
      }
    });

    response += 'Feedback: ' + this.state.rejectionRes;

    return response;

  };

  updateStatus = (newStatus) => {

    this.setState({ responseSent: true });
    if(!this.state.alreadySurveyed) {
      axios.post(`${BASE_URL}/api/survey/answers`, {
        email: this.state.to,
        questions: [
          {
            question: 'Have you worked with us before?',
            answer: this.state.surveyQ1
          },
          {
            question: 'Where didi you first hear about us?',
            answer: this.state.surveyQ2 === 'Other' ? this.state.other : this.state.surveyQ2
          }
        ]
      })
        .catch((err) => {});
    }
    axios.post(`${BASE_URL}/api/approvals/update`, {
        approvalID: this.props.match.params.approvalID,
        response: newStatus,
        userNote: this.getUserResponses()
    }).then(() => {
      this.props.history.push('/approvals/success');
    }).catch(() => {
      this.props.history.push('/approvals/error');
    });
  };

  render() {
    const {
      step,
      id,
      questions,
      jobName,
      document,
      questionIndex,
      inputError,
      activeColumns,
      approved,
      responseSent
    } = this.state;

    const art_object = document.customerartobject;
    const namesNumsLinesConfig = document.namesNumsLinesConfig || {};

    return (
      <>
        <div
          className="flex justify-between flex-row"
        >
          <div
            className="hidden lg:block"
            style={{ 
              width: '93.5px'
            }}
          ></div>
          <div>
            <h1 className="text-bgray text-center font-bold text-xl">
              <span className="text-sm">Job Name: </span>
              {jobName}
            </h1>
            <h2 className="text-bgray text-center font-bold text-lg">
              <span className="text-sm">Order ID: </span>
              {id}
            </h2>
          </div>
          <Button
            className="bg-bblue hover:bg-bblue-dark"
            onClick={this.back}
            style={{
              height: '48px',
              width: '93.5px'
            }}
          >
            Back
          </Button>
        </div>
        <ComponentTransition
          enterAnimation={AnimationTypes.slideLeft.enter}
          exitAnimation={AnimationTypes.slideRight.exit}
          animateContainer={true}
        >
          <div key={step}>
            {step === 'start' && (
              <>
                <div className="flex justify-center">
                  <div className="md:max-w-screen-md flex flex-col justify-center">
                    <p className="text-center text-bgray text-lg">
                      Hey! We would like you to go through your order and make sure
                      that everything looks right. Please help us make sure that
                      your job is the best it possibly can be!
                    </p>
                    <p className="text-center text-bgray text-lg mt-3">
                      If there are any problems please be as descriptive as possible in describing what's wrong.
                    </p>
                    <Button
                      className="bg-bgreen hover:bg-bblue-dark mt-5 m-auto"
                      onClick={this.next}
                    >
                      Begin
                    </Button>
                  </div>
                </div>
              </>
            )}
            {step === 'survey' && (
              <>
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 justify-center">
                    <div className="text-bgray">
                      <h3>Have you worked with us before?</h3>
                      <form className="pl-4 mb-2">
                        <label>
                          <input
                            type="radio"
                            value={true}
                            checked={this.state.surveyQ1 == true}
                            onChange={() => {
                              this.setState({ surveyQ1: true });
                            }}
                          />
                          <span
                            className="p-1 m-1"
                          >
                            Yes
                          </span>
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value={false}
                            checked={this.state.surveyQ1 == false}
                            onChange={() => {
                              this.setState({ surveyQ1: false });
                            }}
                          />
                          <span
                            className="p-1 m-1"
                          >
                            No
                          </span>
                        </label>
                      </form>
                      <h3>Where did you first hear about us?</h3>
                      <form className="pl-4">
                        <label>
                          <input
                            type="radio"
                            value={"Word of Mouth"}
                            checked={this.state.surveyQ2 == 'Word of Mouth'}
                            onChange={() => {
                              this.setState({ surveyQ2: 'Word of Mouth' });
                            }}
                          />
                          <span
                            className="p-1 m-1"
                          >
                            Word of Mouth
                          </span>
                        </label>
                        <br />
                        <label>
                          <input
                            className="pr-4"
                            type="radio"
                            value={"Google Search"}
                            checked={this.state.surveyQ2 == 'Google Search'}
                            onChange={() => {
                              this.setState({ surveyQ2: 'Google Search' });
                            }}
                          />
                          <span
                            className="p-1 m-1"
                          >
                            Google Search
                          </span>
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value={'Instagram'}
                            checked={this.state.surveyQ2 == 'Instagram'}
                            onChange={() => {
                              this.setState({ surveyQ2: 'Instagram' });
                            }}
                          />
                          <span
                            className="p-1 m-1"
                          >
                            Instagram
                          </span>
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value={'Youtube'}
                            checked={this.state.surveyQ2 == 'Youtube'}
                            onChange={() => {
                              this.setState({ surveyQ2: 'Youtube' });
                            }}
                          />
                          <span
                            className="p-1 m-1"
                          >
                            Youtube
                          </span>
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value={'Facebook'}
                            checked={this.state.surveyQ2 == 'Facebook'}
                            onChange={() => {
                              this.setState({ surveyQ2: 'Facebook' });
                            }}
                          />
                          <span
                            className="p-1 m-1"
                          >
                            Facebook
                          </span>
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value={'LinkedIn'}
                            checked={this.state.surveyQ2 == 'LinkedIn'}
                            onChange={() => {
                              this.setState({ surveyQ2: 'LinkedIn' });
                            }}
                          />
                          <span
                            className="p-1 m-1"
                          >
                            LinkedIn
                          </span>
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value={'Drove By'}
                            checked={this.state.surveyQ2 == 'Drove By'}
                            onChange={() => {
                              this.setState({ surveyQ2: 'Drove By' });
                            }}
                          />
                          <span
                            className="p-1 m-1"
                          >
                            Drove By
                          </span>
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value={'Events'}
                            checked={this.state.surveyQ2 == 'Events'}
                            onChange={() => {
                              this.setState({ surveyQ2: 'Events' });
                            }}
                          />
                          <span
                            className="p-1 m-1"
                          >
                            Events
                          </span>
                        </label>
                        <br />
                        <label
                          className="text-black border-black"
                        >
                          <input
                            type="radio"
                            value={'Other'}
                            checked={this.state.surveyQ2 == 'Other'}
                            onChange={() => {
                              this.setState({ surveyQ2: 'Other' });
                            }}
                          />
                          <input
                            type="text"
                            placeholder="Other"
                            className="p-1 m-1"
                            onChange={e => {
                              this.setState({other: e.target.value});
                            }}
                            onFocus={() => {
                              this.setState({ surveyQ2: 'Other' });
                            }}
                          />
                        </label>
                      </form>
                    </div>
                  </div>
                  <div className="text-center">
                    { this.state.inputError && <p className="text-red-500">Please finish the survey! We promise we'll only ask you to do this once.</p> }
                  </div>
                </div>
                <div className="flex justify-end pt-2">
                  <Button
                    className="bg-bblue hover:bg-bblue-dark mr-2"
                    onClick={this.next}
                  >
                    Next
                  </Button>
                  <Button
                    className="bg-bblue hover:bg-bblue-dark"
                    onClick={this.back}
                  >
                    Back
                  </Button>
                </div>
              </>
            )
            }
            {step === 'meta' && (
              <>
                <div 
                  className='flex flex-col justify-center align-center'
                >
                  <Questions
                    questionIndex={questionIndex}
                    questions={questions}
                    inputError={inputError}
                    step={step}
                    showWhy={this.showWhy}
                    setResponse={this.setResponse}
                    neverMind={this.neverMind}
                    questionNext={this.questionNext}
                    next={this.next}
                  />
                </div>
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 justify-center">
                    <div>
                      <h3 className="text-bgray font-medium text-md">
                        Customer:
                      </h3>
                      <div className="text-bgray text-md border-2 rounded-md p-1">
                        <p>
                          {document.customer.firstname}{' '}
                          {document.customer.lastname}
                        </p>
                        <p>{document.customer.address}</p>
                        <p>
                          {document.customer.city}
                          {document.customer.state && ', '}
                          {document.customer.state}
                        </p>
                        <p>{document.customer.zipcode}</p>
                        <p>{document.customer.primaryphone}</p>
                        <p>{document.customer.email}</p>
                      </div>
                    </div>
                    {document.hasshipping !== 0 && (
                      <div>
                        <h3 className="text-bgray font-medium text-md">
                          Shipping:
                        </h3>
                        <div className="text-bgray text-md border-2 rounded-md p-1">
                          <p className="whitespace-pre-line">{document.shipNote || ''}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div> Keep this in case they ever want to add it back in
                    <h3 className="text-white font-medium text-md">
                      Due Date:
                    </h3>
                    <div className="text-white text-md border-2 rounded-md p-1">
                      <p>{document.duedate ? document.duedate : 'None'}</p>
                    </div>
                  </div> */}
                  <div className="max-w-screen-sm m-auto mt-4">
                    <h3 className="text-bgray font-medium text-md">
                      Production Notes:
                    </h3>
                    <div className="text-bgray text-md border-2 rounded-md p-1">
                      <p>{document.specialinstructions ? document.specialinstructions : 'None'}</p>
                    </div>
                  </div>
                </div>
                <hr className="md:w-4/6 m-auto my-4" />
                <div>

                  <div className="flex justify-end pt-2">
                    <Button
                      className="bg-bblue hover:bg-bblue-dark"
                      onClick={this.back}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </>
            )}
            {step === 'lines' && (
              <>
                <div>
                  <Questions
                    questionIndex={questionIndex}
                    questions={questions}
                    inputError={inputError}
                    step={step}
                    showWhy={this.showWhy}
                    setResponse={this.setResponse}
                    neverMind={this.neverMind}
                    questionNext={this.questionNext}
                    next={this.next}
                  />
                </div>
                <div className="mt-7">
                  <p className="visible md:invisible  text-bgray text-center">
                    Swipe to view more
                  </p>
                  <div className="text-bgray text-md overflow-x-auto overflow-x-contain">
                    { this.state.version === 3 ? <div>
                      {this.state.designs.map((design) => {
                        return (
                          <div
                            style={{ 
                              marginBottom: '12px',
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <Lines
                              design={design}
                            />
                          </div>
                        )
                      })} </div>
                      : <OldLines
                        document={document}
                        activeColumns={activeColumns}
                        linesConfig={(document.linesConfig) ? document.linesConfig : {}}
                      />
                    }
                  </div>
                  <p className="invisible md:visible lg:invisible text-bgray text-center">
                    Shift + scroll
                  </p>
                </div>
                <div className="flex flex-col pt-3 space-y-4 md:flex-row md:space-x-4 md:space-y-0 justify-center">
                  <div>
                    <h3 className="text-bgray font-medium text-md">Total Cost:</h3>
                    <div className="text-bgray text-md border-2 rounded-md p-1">
                      ${document.total}
                    </div>
                    <p className="text-sm text-white p-1 pb-0">This number includes tax.</p>
                  </div>
                </div>
                <hr className="md:w-4/6 m-auto my-4" />
                <div>
                  <div className="flex justify-end pt-2">
                    <Button
                      className="bg-bblue hover:bg-bblue-dark"
                      onClick={this.back}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </>
            )}
            {step === 'artwork' && (
              <>
                <th></th>
                <div>
                  <Questions
                    questionIndex={questionIndex}
                    questions={questions}
                    inputError={inputError}
                    step={step}
                    showWhy={this.showWhy}
                    setResponse={this.setResponse}
                    neverMind={this.neverMind}
                    questionNext={this.questionNext}
                    next={this.next}
                  />
                </div>
                <div className="text-bgray text-md overflow-x-auto overflow-x-contain">
                  { this.state.version === 3 ? <div>
                      {this.state.designs.map((design) => {
                        return (
                          <div
                            style={{ 
                              marginBottom: '12px',
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <Lines
                              design={design}
                            />
                          </div>
                        )
                      })} </div>
                    : <OldLines
                        document={document}
                        activeColumns={activeColumns}
                        linesConfig={(document.linesConfig) ? document.linesConfig : {}}
                      />
                  }
                </div>
                <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 justify-center">
                  <div>
                    <h3 className="text-bgray font-medium text-md">Artwork:</h3>
                    <div className="text-bgray text-md md:max-w-screen-md">
                      {document.customerartobject.idArray && document.customerartobject.idArray.map(i => (
                        <img
                          key={i}
                          // src={`https://admin.badgerprints.com/api/documentimages/${document.cache_guid}${document.previewPath}art_lg_${i}.jpg`}
                          src={ `${BASE_URL}/api/documentimages/${art_object.cache_guid}${
                      art_object.previewPath
                    }art_lg_${i.toString() +
                      (art_object.document_id ? ".jpg" : ".png")}`
                          /*`https://stage-cnd.badgerscreenprinting.com/art_lg_${i}.jpg`*/}
                          alt="artwork"
                        />
                      ))}
                    </div>
                  </div>
{/* https://admin.badgerprints.com/api/documentimages/EswNb5b9A/previews/Erin_Anderson/35965/customerArtwork/art_lg_0.jpg */}
                </div>
                {document.coleman_on &&
                  <div className="mt-7 flex flex-col">
                    <h3 className="self-center text-bgray font-medium text-md">Coleman Order:</h3>
                    <div className="text-bgray text-md overflow-x-auto overflow-x-contain">

                      <div
                        className="self-center text-center"
                        width="60%"
                      >
                        <p
                          className="px-2"
                        >Name: {document.coleman_lines.name}</p>
                        <p
                          className="px-2"
                        >Name Font: {document.coleman_lines.nameFont}</p>
                        <p
                          className="px-2"
                        >Size: {document.coleman_lines.size}</p>
                        <p
                          className="px-2"
                        >Year: {document.coleman_lines.year}</p>
                        <p
                          className="px-2"
                        >Special Length Body: {document.coleman_lines.specialLengthBody}</p>
                        <p
                          className="px-2"
                        >School: {document.coleman_lines.school}</p>
                        <p
                          className="px-2"
                        >Body Color: {document.coleman_lines.bodyColor}</p>
                        <p
                          className="px-2"
                        >Sleeve Color: {document.coleman_lines.sleeveColor}</p>
                        <p
                          className="px-2"
                        >Monogramming: {document.coleman_lines.monogramming}</p>
                        <p
                          className="px-2"
                        >Monogramming Line: {document.coleman_lines.monogrammingLine}</p>
                        <p
                          className="px-2 pb-2"
                        >Special Length Sleeve: {document.coleman_lines.specialLengthSleeve}</p>
                      </div>


                      <table className="table-auto m-auto border-black border-solid border-2">
                        <thead>
                          <tr className="">
                            <th
                              className={`bg-gray px-1 approval-table-header text-bgray`}
                              key="emblems"
                            >Additional Emblems</th>
                          </tr>
                        </thead>
                        <tbody>
                          {document.coleman_lines.additional_emblems.map(emblem => {
                            return (
                              <tr key={emblem.id}>
                                <td
                                  className={`border-2 border-black px-1`}
                                  key="emblems"
                              >{emblem.emblems}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>





                    </div>
                </div>
                }
                { document.names_and_nums_on  && 
                  <div className="mt-7 flex flex-col">
                    <h4 className="self-center text-bgray font-medium text-md">Names and/or Numbers:</h4>
                    <div className="text-bgray text-md overflow-x-auto overflow-x-contain">
                      <table className="table-auto m-auto">
                        <thead>
                          <tr className="">
                            {namesTemplate.map(e => {
                              if(Object.keys(namesNumsLinesConfig).length) {
                                if(e.id === 'printoption' || {...namesNumsLinesConfig}[e.id]) {
                                  return (
                                    <th
                                      className={`bg-bgray px-1 approval-table-header text-bgray ${e.mask && e.mask === 'numbers' ? 'w-9' : ''}`}
                                      key={e.label}
                                    >
                                      {e.label}
                                    </th>
                                  )
                                }
                              } else {
                                return (
                                  <th
                                    className={`bg-gray px-1 approval-table-header text-bgray ${e.mask && e.mask === 'numbers' ? 'w-9' : ''}`}
                                    key={e.label}
                                  >
                                    {e.label}
                                  </th>
                                )
                              }
                              return (<></>);
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {document.names_and_nums.map(line => {
                            return (
                            <tr key={line.id}>
                              {namesTemplate.map(e => {

                                if(Object.keys(namesNumsLinesConfig).length) {
                                  if({...namesNumsLinesConfig}[e.id]) {
                                    return (
                                      <td
                                        className={`border-2 border-black px-1`}
                                        key={e.id}
                                      >
                                        {line[e.id]}
                                      </td>
                                    )
                                  }
                                } else {
                                  return (
                                    <td
                                      className={`border-2 border-black px-1`}
                                      key={e.id}
                                    >
                                      {line[e.id]}
                                    </td>
                                  )
                                }

                                return (<></>);
                              })}
                            </tr>
                          )})}
                        </tbody>
                      </table>
                    </div>
                  <p className="invisible md:visible lg:invisible text-bgray text-center">
                    Shift + scroll
                  </p>
                </div>
                }
                <hr className="md:w-4/6 m-auto my-4" />
                <div>
                  <div className="flex justify-end pt-2">
                    <Button
                      className="bg-bblue hover:bg-bblue-dark"
                      onClick={this.back}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </>
            )}
            {step === 'other' && (
              <>
                {/* <hr className="md:w-4/6 m-auto my-4" /> */}
                <div>
                  <Questions
                    questionIndex={questionIndex}
                    questions={questions}
                    inputError={inputError}
                    step={step}
                    showWhy={this.showWhy}
                    setResponse={this.setResponse}
                    neverMind={this.neverMind}
                    questionNext={this.questionNext}
                    next={this.next}
                  />
                  <div className="flex justify-end pt-2">
                    <Button
                      className="bg-bblue hover:bg-bblue-dark"
                      onClick={this.back}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </>
            )}
            {step === 'submit' && (
              <>
                <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 justify-center">
                  <div className="text-bgray md:max-w-screen-md">
                    <h3 className="text-bgray font-bold text-lg text-center mt-2">All Done!</h3>
                    {approved ?
                      <>
                        <p className="p-2">Attention!</p>
                        <p className="p-2">By clicking the submit button, you are approving us to move forward with printing as shown in this approval.</p>
                        <p className="p-2">Please do not click submit unless you are 100% happy with your order!</p>
                      </>
                      :
                      <>
                        <p className="p-2">It looks like you answered no to some of the questions. If there was anything that wasn't addressed in one of the previous questions please take the time to address it below. When you're ready please click the submit button and we'll get started fixing your job.</p>
                        <p className="p-2">If your job is a rush please call or email us (contact info below), just in case we don't see the approval right away in our system.</p>
                        <TextArea
                          value={this.state.rejectionRes}
                          className={"mt-2"}
                          placeholder="Please describe what's wrong"
                          onChange={(e) => {
                            this.setState({rejectionRes: e.target.value});
                          }}
                        ></TextArea>
                      </>
                    }
                  </div>
                </div>
                <div>
                  <div className="flex justify-center pt-2">
                    {responseSent ?
                     <Button
                      className="bg-bblue hover:bg-bblue-dark"
                     >
                       Loading...
                     </Button>
                     : approved ?
                        <Button
                          className="bg-bblue hover:bg-bblue-dark"
                          onClick={() => {this.updateStatus(1)}}
                        >
                          Submit Approval
                        </Button>
                        :
                        <Button
                          className="bg-byellow hover:bg-bred"
                          onClick={() => {this.updateStatus(2)}}
                        >
                          Submit Rejection
                        </Button>
                    }

                  </div>
                </div>
                <div>
                  <div className="flex justify-end pt-2">
                    <Button
                      className="bg-bblue hover:bg-bblue-dark"
                      onClick={this.back}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </ComponentTransition>
      </>
    );
  }
}

const Approvals = withRouter(ApprovalsPage);

function OldLines(props) {
  return (
    <table className="table-auto m-auto">
      <thead>
        <tr className="">
          {OldColsTemplate.map(e => {
            if ({...props.activeColumns, ...props.linesConfig.activeColumns}[e.id]) {
              return (
                <th
                  className={`bg-bgray px-1 approval-table-header text-bgray ${e.mask && e.mask === 'numbers' ? 'w-9' : ''}`}
                  key={e.label}
                >
                  {e.label}
                </th>
              )
            } else {
              return (<></>);
            }
          })}
        </tr>
      </thead>
      <tbody>
        {props.document.lines.map(line => (
          <tr key={line.id}>
            {OldColsTemplate.map(e => {
              if ({...props.activeColumns, ...props.linesConfig.activeColumns}[e.id]) {
                return (
                  <td
                    className={`border-2 border-black px-1`}
                    key={e.id}
                  >
                    {line[e.id]}
                  </td>
                )
              } else {
                return (<></>);
              }
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function Lines(props) {
  const design = props.design;
  return (
    <div
      style={{ width: '1000px' }}
    >
      <p><strong>Design {design.designNumber}</strong></p>
      <p>
        <strong>Print: {design.fPrint} x {design.bPrint} x {design.lPrint} x {design.rPrint}
          {(design.otherPrints ?? []).map((print) => {
            return ' x ' + print;
          })}
        </strong>
      </p>
      <p>
        <strong>
          Customizations: {(design.design_customizations ?? []).map((custo) => {
            return `${custo.name} - $${custo.price.toFixed(2)}, `;
          })}
        </strong>
      </p>
      <table className="table-auto m-auto">
        <thead>
          <tr className="">
            {ColsTemplate.map(e => {
              if (!design.hiddenColumns.includes(e.key)) {
                return (
                  <th
                    className={`bg-black px-1 approval-table-header text-white`}
                    key={e.headerName}
                  >
                    {e.headerName}
                  </th>
                )
              } else {
                return (<></>);
              }
            })}
          </tr>
        </thead>
        <tbody>
          {design.orderLines.map(line =>{
            return (
              <tr key={line.id}>
                {ColsTemplate.map((e, i) => {
                  if (!design.hiddenColumns.includes(e.key)) {
                    return (
                      <td
                        className={`border-2 border-black px-1`}
                        key={line.id + i}
                      >
                        {line[e.key]}
                      </td>
                    );
                  } else {
                    return (<></>);
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

function Button(props) {
  return (
    <button
      className={`transition-all duration-250 ease-in-out inline-block text-bgray font-semibold px-6 py-3 rounded-md uppercase ${props.className}`}
      onClick={props.onClick}
      style={{
        //textShadow: '0px 0px 12px #000000',
        ...props.style
      }}
    >
      {props.children}
    </button>
  );
}

function TextArea(props) {
  return (
    <textarea
      className={`w-full px-3 py-2 text-bgray bg-white border-2 rounded-lg focus:outline-none focus:border-bblue ${props.className}`}
      rows="4"
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value}
    ></textarea>
  );
}

function Questions(props) {
  return (
    <ComponentTransition
      enterAnimation={AnimationTypes.scale.enter}
      exitAnimation={AnimationTypes.scale.exit}
      animateContainer={true}
    >
      <div key={props.questionIndex}>
        {props.questions[props.step].map((listItem, index) => (
          <div key={index}>
            {props.questionIndex === index && (
              <div className="text-bgray flex justify-center">
                <div className="w-full md:w-7/12 lg:w-/12">
                  <h2 className="font-semibold text-lg">{listItem.question}</h2>
                  <p>{listItem.description}</p>
                  {listItem.response !== undefined ? (
                    <>
                      <TextArea
                        value={listItem.response}
                        className={`mt-2 grow-2 ${
                          props.inputError ? 'border-bred' : ''
                        }`}
                        placeholder="Please describe what's wrong"
                        onChange={(e) =>
                          props.setResponse(
                            props.step,
                            props.questionIndex,
                            e.target.value
                          )
                        }
                      ></TextArea>
                      <div className="my-2 space-x-3 flex justify-end">
                        <Button
                          className="bg-bgreen hover:bg-bgreen-dark"
                          onClick={() =>
                            props.neverMind(props.step, props.questionIndex)
                          }
                        >
                          Never Mind
                        </Button>
                        <Button
                          className="bg-bblue hover:bg-bblue-dark"
                          onClick={() =>
                            props.questionNext(props.step, props.questionIndex)
                          }
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                        <div className="my-2 space-x-3 flex justify-end">
                          {listItem.allowNA === true && (
                            <Button
                              className="bg-bblue hover:bg-bblue-dark"
                              onClick={props.next}
                            >
                              N/A
                            </Button>
                          )}
                          <Button
                            className="bg-bgreen hover:bg-bgreen-dark"
                            onClick={props.next}
                          >
                            Yes
                          </Button>
                          <Button
                            className="bg-byellow hover:bg-bred"
                            onClick={() =>
                              props.showWhy(props.step, props.questionIndex)
                            }
                          >
                            No
                          </Button>
                        </div>
                        <div>
                          <p class="text-xs text-right">By answering yes, you acknowledge that this is what will be printed</p>
                        </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </ComponentTransition>
  );
}
