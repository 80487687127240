export default function PaymentsRoute() {
  // let match = useRouteMatch();
  // console.log(paymentID)
  return (
    <>
      <div className="text-center mb-3">
        <h2 className="text-black text-xl font-bold">
          Payment link no longer valid.
        </h2>
      </div>
      <div className="text-center mb-3">
        <p className="text-black">
          Hey sorry you are seeing this page. Either this payment has already been completed, or the link was manually disabled on our end.
        </p>
        <p className="text-black">
          If you have any questions please feel free to reach out via the contacts below.
        </p>
      </div>
    </>
  );
}


                    