import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  withRouter,
  useHistory
} from "react-router-dom";
import axios from 'axios';
import './App.css';
import { ApprovalsRoute } from './components/Approvals'
import InvalidPayment from './components/InvalidPayment';
import ValidPayment from './components/ValidPayment';
import SuccessfulPayment from './components/SuccessfulPayment';
import TrackOrder from './components/TrackOrder';
import { BASE_URL } from './api';


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/payments">
          <main className="bg-bgray">
            <section className="absolute w-full items-center flex sm:h-full min-h-full bg-bgray">
              <div
                className="absolute top-0 w-full h-full bg-bgray"
                style={{
                  backgroundImage:
                  "url(https://cnd.badgerprints.com/web-assets/badger/PaymentBackground.png)",
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat"
                }}
              ></div>
              <PaymentsRoute />
            </section>
          </main>
        </Route>
        <Route path="/approvals">
          <main className="bg-bgray">
            <section className="absolute w-full items-center flex sm:h-full min-h-full bg-bgray">
              <div
                className="absolute top-0 w-full h-full bg-bgray"
                style={{
                  backgroundImage:
                  "url(https://cnd.badgerprints.com/web-assets/badger/PaymentBackground.png)",
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat"
                }}
              ></div>
              <ApprovalsRoute />
            </section>
          </main>
        </Route>
        <Route path="/track">
          <TrackOrder />
        </Route>
        <Route path="/">
          <Redirect
            to={{
              pathname: "/payments",
            }}
          />
        </Route>
      </Switch>
    </Router>
  );
}
function PaymentsRoute() {
  let match = useRouteMatch();
  let history = useHistory();
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 md:w-8/12">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className=""
                  style={{
                    backgroundImage: 'url(https://cnd.badgerprints.com/web-assets/badger/badger-Logo.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    height: '100px',
                    backgroundSize: 'contain'
                  }}
                >
                </div>
                <Switch>
                  <Route exact path={match.path}>
                    <h3 className="text-center text-lg font-bold mb-2">Hmm... </h3>
                    <p>Looks like you clicked on a bad link. Go ahead and try again.</p>
                    <p>If you are still having trouble please reach out via the contact info below. We'd love to hear from you.</p>
                  </Route>
                  <Route exact path={`${match.path}/success`}>
                   <SuccessfulPayment history={history} />
                  </Route>
                  <Route exact path={`${match.path}/error`}>
                    <div className="text-center mb-3">
                      <h2 className="text-black text-lg font-bold">
                        Unable to process your payment.
                      </h2>
                      <p>
                        <strong><em>Do not</em></strong> try to pay again, instead give us a call at (435) 787-1636 to ensure that your payment has not already gone through.
                      </p>
                    </div>
                  </Route>
                  <Route exact path={`${match.path}/declined`}>
                    <div className="text-center mb-3">
                      <h2 className="text-black text-lg font-bold">
                        Unable to process your payment because <i>your card was declined</i>.
                      </h2>
                      <p>
                        Please try a different card. If the problem persists, please
                        give us a call at (435) 787-1636 to ensure that your payment has not already gone through.
                      </p>
                    </div>
                  </Route>
                  <Route path={`${match.path}/p/:paymentID`}>
                    <Payments />
                  </Route>
                </Switch>
                
                <div className="text-center mt-2">
                  <hr />
                </div>
                <div className="text-center mt-2">
                  <h2 className="text-black text-xl font-bold">
                    Badger Screen Printing © { new Date().getFullYear()}
                  </h2>
                </div>
                <div className="text-center mt-1">
                  <h2 className="text-black text-lg font-bold">
                    <a href="http://badgerprints.com">badgerprints.com</a>
                  </h2>
                </div>
                <div className="text-center mt-1">
                  <h2 className="text-black text-sm font-medium">
                    (435) 787-1636 - <a href="mailto:art@badgerprints.com">art@badgerprints.com</a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

class PaymentsDetails extends React.Component {
  constructor(props) {
    super()
    const id = props.match.params.paymentID;
    this.state = {
      id,
      isValid: true,
      paymentDetails: {
        jobName: '',
        document_id: '',
        amount: 0,
        sent: '',
      }
    };
  }

  checkExpired(dateToCheck) {
    const date = new Date(dateToCheck);
    const now = new Date(Date.now()) 

    //Converts the milliseconds to hours and then days
    const days = (now - date) / (1000 * 60 * 60) / 24 

    return (days > 10)
  }

  componentDidMount() {
    // get the useful stuff!!
    axios.get(`${BASE_URL}/api/payments/${this.state.id}`)
      .then(res => {
        this.setState({paymentDetails: res.data});

        if(this.checkExpired(res.data.sent)) this.setState({isValid: false})

      })
      .catch(e => {
        console.log(e)
        this.setState({isValid: false});
      })
  }
  
  render () {
  return (
      <>
        { this.state.isValid ? <ValidPayment data={this.state.paymentDetails} id={this.state.id} history={this.props.history} /> : <InvalidPayment /> }
      </>
    )
  }
}

const Payments = withRouter(PaymentsDetails);

export default App;
