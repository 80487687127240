export default function Footer(props) {
  return (
    <div className="relative text-white">
      <div
        className="w-full max-w-xs"
        style={{
          backgroundImage:
            'url(https://cnd.badgerprints.com/web-assets/badger/badger-Logo.png)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          height: '100px',
          backgroundSize: 'contain',
        }}
      />
      <div className="text-center">
        <hr />
      </div>
      <div className="text-center mt-2">
        <h2 className="text-xl font-bold">
          Badger Screen Printing © {new Date().getFullYear()}
        </h2>
      </div>
      <div className="text-center mt-1">
        <h2 className="text-lg font-bold">
          <a href="http://badgerprints.com" className="underline">badgerprints.com</a>
        </h2>
      </div>
      <div className="text-center mt-1">
        <h2 className="text-sm font-medium">
          (435) 787-1636 -{' '}
          <a href="mailto:art@badgerprints.com">art@badgerprints.com</a>
        </h2>
      </div>
    </div>
  )
}